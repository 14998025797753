import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Eagle is an experimental workload management system to help creative teams using Podio visualize and plan workload.`}</p>
    <p>{`The application works on the premises that creative professionals juggle multiple projects at once, making incremental progress on each. It also assumes that margin must be planned to leave room for the unexpected.`}</p>
    <p>{`I made use of JSON services from the Podio system to tap into the projects and workload settings the users edit directly in that system. This data is then visualized on a timeline with progress bars that are color-coded to indicate workload.`}</p>
    <p>{`Users can browse the overall project workload or look at individual workers' load aggregates to see where work can be planned, or where overload might occur.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/eagle-sample.png",
        "alt": null
      }}></img></p>
    <p>{`This application is built with PHP/MySQL and the CodeIgniter framework. It employs a good bit of JavaScript and jQuery to add the front-end functionality.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      